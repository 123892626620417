import axios from "axios"
import qs from "qs"
import { buildWebStorage, setupCache } from "axios-cache-interceptor"
import { get } from "lodash"

import errorHandler from "./errorHandler"

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
})

axiosInstance.interceptors.request.use(
  (config) => {
    let tokens = []
    try {
      tokens = JSON.parse(localStorage.getItem("token") || "[]")
    } catch (err) {
      tokens = [""]
    }

    let user
    try {
      if (tokens.length > 0) {
        user = JSON.parse(localStorage.getItem("user"))
      }
    } catch (e) {
      //
    }
    if (!Array.isArray(tokens)) tokens = [""]
    const temporaryToken = sessionStorage.getItem("token")

    return {
      ...config,
      url: `${config.url ?? ""}${config.url.includes("?") ? "" : "?"}${qs.stringify({
        profile_id: get(user, "id"),
        latlong: window.latlong,
        ...config.params,
      })}`,
      headers: {
        Authorization: `Bearer ${temporaryToken || tokens[0]}`,
        "Accept-Language": "uz",
        Accept: "aplication/json",
      },
      params: {},
    }
  },
  (error) => error,
)

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    errorHandler(error)
    return error
  },
)

const headerInterpreter = (headers) => {
  if (headers["x-my-custom-header"]) {
    const seconds = Number(headers["x-my-custom-header"])

    if (seconds < 1) {
      return "dont cache"
    }

    return seconds
  }

  return "not enough headers"
}

const api = setupCache(axiosInstance, {
  storage: buildWebStorage(localStorage, "axios-cache"),
  headerInterpreter,
  cachePredicate: ({ config }) => config.cache.ttl > 0,
})

export default api
