import dayjs from "dayjs"
import calendar from "dayjs/plugin/calendar"
import "dayjs/locale/uz-latn"

dayjs.locale("uz-latn")

dayjs.extend(calendar)

const isValid = (timestamp) => dayjs(timestamp).isValid()

const to = (timestamp, format = "DD.MM.YYYY HH:mm:ss") =>
  isValid(timestamp) ? dayjs(timestamp).format(format) : ""
const month = (num) => dayjs().month(num).format("MMMM")
const unixToDateObject = (value) => (value ? new Date(to(+value, "YYYY-MM-DD")) : null)

const toChat = (timestamp) =>
  dayjs(timestamp).calendar(null, {
    sameDay: "[Today at] h:mm A", // The same day ( Today at 2:30 AM )
    nextDay: "[Tomorrow at] h:mm A", // The next day ( Tomorrow at 2:30 AM )
    nextWeek: "dddd [at] h:mm A", // The next week ( Sunday at 2:30 AM )
    lastDay: "[Yesterday at] h:mm A", // The day before ( Yesterday at 2:30 AM )
    lastWeek: "[Last] dddd [at] h:mm A", // Last week ( Last Monday at 2:30 AM )
    sameElse: "DD/MM/YYYY", // Everything else ( 17/10/2011 )
  })

const toYear = (timestamp) => dayjs.unix(timestamp).get("year")
const toUnix = (timestamp) => dayjs(timestamp).unix()

export default { to, toChat, toYear, isValid, toUnix, unixToDateObject, month }
